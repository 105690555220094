<template>
    <div class="common-form" v-if="currentComponent !== 'InfoMeeting'">
        <!-- 标题 -->
        <div class="common-form-layout">
            <global-page-back
            :detailPageTitle="dialogTitle"
            @handlerGlobalType="routerList"
            >
                <div  slot="globalPageBackTabs" v-if="currentComponent !== 'InfoMeeting'" class="switch-list">
                    <div style="width: 1px;height: 16px;background: #E1E3E6;margin: 0 28px 0 58px;"></div>
                    <div class="switch-list-item">
                        <div
                            :class="currentComponent === 'MeetingDetail' ? 'switch-item current-item' : 'switch-item'"
                            @click="switchClick('MeetingDetail')"
                        >基本数据</div>
                        <div
                            :class="currentComponent === 'MeetingSummary' ? 'switch-item current-item' : 'switch-item'"
                            @click="switchClick('MeetingSummary')"
                        >纪要记录</div>
                        <div
                            :class="currentComponent === 'MeetingScreenRecording' ? 'switch-item current-item' : 'switch-item'"
                            @click="switchClick('MeetingScreenRecording')"
                        >录屏记录</div>
                    </div>

                </div>
            </global-page-back>
            <div class="main-page meetings_infos content-bg">
                <component :is="currentComponent" @switchClick="switchClick" @meetingIdChange="meetingIdChange"
                    @routerReturnChange="routerReturnChange" :meetingId="meetingId"
                    :routerReturn="routerReturn" @basicBackClick="routerList"></component>
            </div>
        </div>

    </div>
</template>

<script>
import MeetingDetail from "./SManagerOaMeetingOnlineWrapper/MeetingDetail.vue"
import MeetingSummary from "./SManagerOaMeetingOnlineWrapper/MeetingSummary.vue"
import MeetingScreenRecording from "./SManagerOaMeetingOnlineWrapper/MeetingScreenRecording.vue"
import GlobalPageBack from './Sub/GlobalPageBack/index';

export default {
    name: "SManagerOaMeetingOnlineWrapper",
    components: {MeetingDetail, MeetingSummary, MeetingScreenRecording,GlobalPageBack},
    props: {
        meetingId: {
            type: String,
            required: true
        },
        routerReturn: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            currentComponent: "MeetingDetail",
            dialogTitle:'编辑会议'
        }
    },
    created() {
        this.$eventDispatch('setGlobalPageType','form')
        // const permissions = this.$store.state.permissions
        // if (permissions.includes("online:sponsor")) {
        // 	// 基本数据
        //     this.currentComponent = "MeetingDetail"
        // } else if (permissions.includes("online:willAttend")) {
        // 	// 纪要记录
        //     this.currentComponent = "MeetingSummary"
        // } else if (permissions.includes("online:attended")) {
        // 	// 录屏记录
        //     this.currentComponent = "MeetingScreenRecording"
        // }
    },
    mounted(){
       let state= sessionStorage.getItem('is_edit');
       console.log(state,'[][]')
       if(state=='edit'){
        this.dialogTitle='编辑会议'
       }else{
        this.dialogTitle='会议详情'
       }
    },
    methods: {
        switchClick(page) {
            this.currentComponent = page
        },
        meetingIdChange(id) {
            this.meetingId = id
        },
        routerReturnChange(path) {
            this.routerReturn = path
        },
        /**
         * @Description: 返回会议列表组件
         * @DoWhat: 传递 "" 给父组件
         * @UseScenarios:
         * @Attention: 返回上一级，必须传值为 ""
         * @Author:
         * @Date:
         */
        routerList() {
            if (this.routerReturn === "") {
                this.$emit("switchPage", "")
                this.$eventDispatch('setGlobalPageType','list')
            } else {
                this.$emit("switchClick", this.routerReturn)
                this.$emit("routerReturnChange", undefined)
                this.$eventDispatch('setGlobalPageType','list')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    .switch-list{
        height: 100%;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        align-items: center;
        .switch-list-item {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

            .switch-item {
                cursor: pointer;
                color: #606C80;
                font-size: 15px;
                margin-right: 48px;
            }

            .current-item {

                font-weight: bold;
                color: #2B2E33;
                font-size: 15px;
                line-height: 48px;
                border-bottom: solid 2px #3C7FFF;
            }
        }
    }


    .meetings_infos .return-btn {
        display: flex;
        color: #363A40;
        line-height: 1;
        cursor: pointer;
        font-size: 14px;
        margin-right: 20px 0 20px 20px;
        align-items: center;
        &:hover{
            color: #3C7FFF
        }
        i {
            // color: #808387;
            margin-right: 5px;
        }
        i:hover{
            color: #3C7FFF  !important;
        }
    }
    // .global-page-back .back-wrapper{
    //     position: fixed;
    // }
    // .global-page-back .tab-list{
    //     justify-content: center;
    //     width: 100%;
    // }
</style>
