<template>
    <div class="meeting-info">
		<!-- 会议管理  我已参会 详情-->
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow" class="content-wrapper">
                <div class="main-box">
                    <div class="return-btn" @click="routerList"><i class="el-icon-back"> 返回</i></div>
                    <div class="body-box">
                        <div class="basic-box">
                            <div class="title-bar">视频会议基本数据</div>
                            <div class="basic-list">
								<div class="basic-item">
								    <span>状态</span>
								    <em>{{ meetingData.status === '0'? '未开始' :  meetingData.status === '1'? '进行中' :'已结束' }}</em>
								</div>
                                <div class="basic-item">
                                    <span>标题</span>
                                    <em>{{ meetingData.title }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>类型</span>
                                    <em>{{ meetingData.typeName }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>方式</span>
                                    <em>{{ meetingData.mode ==='1' ? '自主发起' : '预约'}}</em>
                                </div>
                                <div class="basic-item">
                                    <span>科目</span>
                                    <em>{{ meetingData.subject }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>年级</span>
                                    <em>{{ gradeName }}</em>
                                </div>
								<div class="basic-item">
								    <span>创建人</span>
								    <em>{{ meetingData.createName }}</em>
								</div>
                                <div class="basic-item">
								    <span>预约时间</span>
								    <em v-if="meetingData.appointStartTime">{{ startTime}} -{{ endTime}}</em>
								</div>
								<div class="basic-item">
								    <span>创建时间</span>
								    <em>{{ meetingData.createTime }}</em>
								</div>
                                <div class="basic-item">
                                    <span>开始时间</span>
                                    <em>{{ meetingData.startTime }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>会议时长</span>
                                    <em>{{ meetingData.meetingDuration }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>发起人</span>
                                    <em>{{ meetingData.conferenceSponsor }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>邀请人数</span>
                                    <em>{{ meetingData.meetingNumber }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>已入会人数</span>
                                    <em>{{ meetingData.joinedNumber }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>参会时长不足人数</span>
                                    <em>{{ meetingData.notEnoughTimeNumber }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>未入会人数</span>
                                    <em>{{ meetingData.noJoinedNumber }}</em>
                                </div>
                                <div class="basic-item">
                                    <span>参会比例</span>
                                    <em>{{ meetingData.participationRatio }}</em>
                                </div>
								<div class="basic-item">
								    <span>提醒</span>
								    <em>{{ meetingData.remindTime }}</em>
								</div>
                            </div>
                        </div>
                        <div class="table-box">
                            <div class="title-bar">视频会议参会者数据</div>
                            <el-table :data="meetingData.attendeeList" stripe height="calc(100% - 36px)"
                                      :header-cell-style="headerCellStyle" :row-style="rowStyle">
                                <el-table-column prop="name" label="姓名" width="80" :show-overflow-tooltip="true"
                                                 align="center"></el-table-column>
                                <el-table-column prop="className" label="班级" width="80" :show-overflow-tooltip="true"
                                                 align="center">
                                </el-table-column>
                                <el-table-column prop="grade" label="年级" width="80" :show-overflow-tooltip="true"
                                                 align="center"></el-table-column>
                                <el-table-column prop="school" label="学校" :show-overflow-tooltip="true">
                                </el-table-column>
                                <el-table-column prop="firstJoiningTime" label="首次入会时间" width="180"
                                                 :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column prop="lastDepartureTime" label="最后离开时间" width="180"
                                                 :show-overflow-tooltip="true"></el-table-column>
                                <el-table-column prop="duration" label="时长" width="80" :show-overflow-tooltip="true"
                                                 align="center"></el-table-column>
                                <el-table-column prop="notJoiningReason" label="未入会原因" width="100"
                                                 :show-overflow-tooltip="true" align="center">
                                </el-table-column>
                                <el-table-column prop="isEnoughTime" label="参会时长是否不足" width="140"
                                                 :show-overflow-tooltip="true" align="center">
                                    <template slot-scope="scope">
                                        {{ scope.row.isEnoughTime === "1" ? "是" : "否" }}
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>

<script>
import {Loading} from "common-local"

export default {
    components: {Loading},
    props: {
        meetingId: {
            type: String,
            required: true
        },
        routerReturn: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            loadingShow: false,
            errorShow: false,
            startTime:'',//预约时间开始时间
            endTime:"",//预约时间的结束时间
            headerCellStyle: {
                height: "50px",
                backgroundColor: "#E6ECF2"
            },
            rowStyle: {
                height: "50px",
            },
            meetingData: {}
        }
    },
    computed: {
        gradeName() {
            let parentName = "";
            let gradeName = "";
            parentName = this.meetingData.parentName ? `${this.meetingData.parentName}/` : "";

            gradeName = this.meetingData.grade ?this.meetingData.grade : "";
            return `${parentName}${gradeName}`
        }
    },
    created() {
        this.getMeetingInfo()
    },
    methods: {
        /**
         * @Description: 返回会议列表组件
         * @DoWhat: 传递 "" 给父组件
         * @UseScenarios:
         * @Attention: 返回上一级，必须传值为 ""
         * @Author: 马枫茂
         * @Date: 2022-11-08 15:49:06
         */
        routerList() {
            if (this.routerReturn === "") {
                this.$emit("switchPage", "")
            } else {
                this.$emit("switchClick", this.routerReturn)
                this.$emit("routerReturnChange", undefined)
            }
        },
        /**
         * @Description: 获取会议信息
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 马枫茂
         * @Date: 2022-11-09 16:08:09
         */
        getMeetingInfo() {
            this._fet("/school/schoolMeetingAnalysis/getInfoById", {
                id: this.meetingId
            }).then(res => {
                if (res.data.code === "200") {
                    this.meetingData = res.data.data
                    this.meetingData.typeName=this.meetingData.typeName+'('+this.meetingData.validTime+')'
                    //修改预约时间的格式
                    if(this.meetingData.appointEndTime){
                        var dateObj = new Date(this.meetingData.appointEndTime)
                        var hours=dateObj.getHours()<10 ? "0"+dateObj.getHours() : dateObj.getHours();
                        var minutes=dateObj.getMinutes()<10 ? "0"+dateObj.getMinutes() : dateObj.getMinutes();
                        this.endTime = hours+":"+minutes;
                    }
                    if(this.meetingData.appointStartTime){
                        var dateObj = new Date(this.meetingData.appointStartTime)
                        var year = dateObj.getFullYear();
                        var month= dateObj.getMonth()+1<10 ? "0"+(dateObj.getMonth()+1) : dateObj.getMonth()+1;
                        var day=dateObj.getDate()<10 ? "0"+dateObj.getDate() : dateObj.getDate();
                        var hours=dateObj.getHours()<10 ? "0"+dateObj.getHours() : dateObj.getHours();
                        var minutes=dateObj.getMinutes()<10 ? "0"+dateObj.getMinutes() : dateObj.getMinutes();
                        this.startTime = year +"-"+month+"-"+day+" "+hours+":"+minutes
                    }
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.meeting-info {
    .content-wrapper {
        padding: 10px;
        box-sizing: border-box;
    }

    .main-box {
        padding: 15px;
        border-radius: 6px;
        box-sizing: border-box;
        background-color: #ffffff;
        height: calc(100vh - 128px);

        .title-bar {
            margin-bottom: 20px;
        }
    }

    .return-btn {
        display: flex;
        color: #363A40;
        line-height: 1;
        cursor: pointer;
        font-size: 14px;
        align-items: center;
        margin-bottom: 20px;

        &:hover{
                color: #3C7FFF;
            }

        i {
            color: #363A40;
            margin-right: 5px;

            &:hover{
                color: #3C7FFF;
            }
        }
    }

    .body-box {
        display: flex;
        height: calc(100% - 34px);

        .basic-box {
            width: 430px;
            padding: 20px;
            flex-shrink: 0;
            padding-bottom: 0;
            padding-left: 40px;
            border-radius: 6px;

            .basic-list {
                overflow-y: auto;
                height: calc(100% - 38px - 16px);
            }

            .basic-item {
                display: flex;
                color: #808387;
                font-size: 14px;
                margin-top: 26px;
                align-items: center;

                &:first-child {
                    margin-top: 0;
                }
            }

            span {
                width: 50%;
            }

            em {
                width: 50%;
                color: #363A40;
                font-style: normal;
            }
        }

        .table-box {
            height: 100%;
            padding: 20px;
            border-radius: 6px;
            box-sizing: border-box;
            width: calc(100% - 490px);
            background-color: #F7F7F7;

            .title-bar {
                margin-bottom: 20px;
            }

            .text-overflow {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .title-bar {
        color: #363B40;
        line-height: 1;
        font-size: 16px;
        padding-left: 10px;
        border-left: solid 3px #3C7FFF;
    }
}
</style>
